<template>
  <div class="resume">
    <em>我的简历页面-暂时保留</em>
  </div>
</template>

<script>
export default {
  name: 'Resume'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.resume{
  em{
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }
}
</style>